import React from 'react'

const ErrorList = (props: errorListInterface) => {
  return props.errors.length > 0 ? (
    <ul className="browser-default">
      {props.errors.map((error: string) => {
        return (
          <li className="red-text" key={error}>
            {error}
          </li>
        )
      })}
    </ul>
  ) : (
    <div />
  )
}

export default ErrorList
