import React from 'react'
import { Row, Col, Card, TextInput, Button } from 'react-materialize'
import { Loader } from 'react-feather'
import axios from 'axios'
import ErrorList from '../layout/ErrorList'
import Form from '../layout/Form'

export default class NewSession extends Form<signInInterface> {
  state = {
    email: '',
    password: '',
    errors: [],
    loading: false,
  }

  constructor(props: signInInterface) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
  }

  handleSubmit(e: Event): void {
    e.preventDefault()

    this.setState({ loading: true })

    const { email, password } = this.state

    axios
      .post('/sign-in', {
        authenticity_token: this.props.csrfToken,
        supporter: {
          email,
          password,
        },
      })
      .then((response) => {
        window.location.replace(response.data.url)
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data.errors,
          loading: false,
        })
      })
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleEnter(e: React.KeyboardEvent) {
    const target = e.target as HTMLInputElement
    if (e.key === 'Enter') {
      this.setState({
        [target.name]: target.value,
      })
    }
  }

  render() {
    const { errors, password, email, loading } = this.state

    return (
      <Row>
        <Col l={6} s={12} push="l3">
          <Card title="Sign In">
            <form>
              {loading ? (
                <div className="center-align">
                  <Loader size={30} />
                </div>
              ) : (
                <ErrorList errors={errors} />
              )}
              <Row>
                <TextInput
                  s={12}
                  name="email"
                  label="Email"
                  email={true}
                  autoComplete="username email"
                  defaultValue={email}
                  onBlur={this.handleChange}
                  onKeyDown={this.handleEnter}
                />
                <TextInput
                  s={12}
                  name="password"
                  label="Password"
                  password={true}
                  autoComplete="current-password"
                  defaultValue={password}
                  onBlur={this.handleChange}
                  onKeyDown={this.handleEnter}
                />
              </Row>
              <Row>
                <Col s={12} className="center-align">
                  <Button type="submit" onClick={this.handleSubmit}>
                    Sign in
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col s={12} className="center-align">
                  <a className="coral-link" href="/password/new">
                    Forgot your password?
                  </a>
                </Col>
              </Row>
            </form>
          </Card>
        </Col>
      </Row>
    )
  }
}
